// Random restaurant data that is added to Firestore
// After you've signed into Friendly Eats, click the dropdown
// menu in the top right corner and select "Add random data"

export const randomData = {
	restaurantNames: [
		"Savory Bites",
		"Gourmet Delight",
		"Wholesome Kitchen",
		"Cheesy Cravings",
		"Spice Fusion",
		"Burger Bonanza",
		"Pasta Paradise",
		"Taco Tango",
		"Sushi Sensation",
		"Pizza Pizzazz",
		"Cafe Mocha",
		"Mouthwatering BBQ",
		"Thai Temptations",
		"Sizzling Steaks",
		"Veggie Heaven",
		"Seafood Symphony",
		"Wok & Roll",
		"French Flavors",
		"Tandoori Nights",
		"Mediterranean Magic",
		"Enchilada Express",
		"Ramen Ramble",
		"Deli Delights",
		"Crepes & Co.",
		"Hot Pot Haven",
		"Tasty Tandoor",
		"Bistro Bliss",
		"Curry Corner",
		"Pancake Paradise",
		"Pita Panache",
		"Biryani Bliss",
		"Garden Grub",
		"Dim Sum Delish",
		"Cajun Craze",
		"Fondue Fantasy",
		"Bagel Bar",
		"Tapas Talk",
		"Pho Fusion",
		"Brunch Bunch",
		"Steaming Samosas",
		"Falafel Frenzy",
	],
	restaurantCities: [
		"New York",
		"Los Angeles",
		"London",
		"Paris",
		"Tokyo",
		"Mumbai",
		"Dubai",
		"Amsterdam",
		"Seoul",
		"Singapore",
		"Istanbul",
	],
	restaurantCategories: [
		"Italian",
		"Chinese",
		"Japanese",
		"Mexican",
		"Indian",
		"Mediterranean",
		"Caribbean",
		"Cajun",
		"German",
		"Russian",
		"Cuban",
		"Organic",
		"Tapas",
	],
	restaurantReviews: [
		{ text: "The food was exceptional, absolutely loved it!", rating: 5 },
		{ text: "Delicious dishes and excellent service!", rating: 5 },
		{ text: "The flavors were so rich and satisfying.", rating: 5 },
		{ text: "Great ambiance and friendly staff.", rating: 5 },
		{ text: "A delightful culinary experience!", rating: 5 },
		{
			text: "Mouthwatering dishes that left me wanting more.",
			rating: 5,
		},
		{ text: "Perfectly cooked and seasoned meals.", rating: 5 },
		{ text: "Incredible presentation and top-notch taste.", rating: 5 },
		{ text: "Good food and cozy atmosphere.", rating: 4 },
		{ text: "Enjoyed the meal, would come back again.", rating: 4 },
		{ text: "Tasty options and reasonable prices.", rating: 4 },
		{ text: "Friendly staff, but the food was just okay.", rating: 3 },
		{ text: "Decent experience, but nothing extraordinary.", rating: 3 },
		{ text: "The food was average, could be better.", rating: 3 },
		{
			text: "Service was slow, and the dishes were disappointing.",
			rating: 2,
		},
		{ text: "Expected more, but left unsatisfied.", rating: 2 },
		{ text: "Underwhelming taste and presentation.", rating: 2 },
		{ text: "Disappointing experience overall.", rating: 2 },
		{
			text: "The food was terrible, will never go back again.",
			rating: 1,
		},
		{
			text: "Worst restaurant experience I've had so far.",
			rating: 1,
		},
		{ text: "Avoid this place, the food was inedible.", rating: 1 },
		{ text: "Unpleasant service and tasteless dishes.", rating: 1 },
		{ text: "Simply outstanding! A culinary masterpiece.", rating: 5 },
		{ text: "Couldn't get enough of the amazing flavors.", rating: 5 },
		{ text: "Top-notch quality, worth every penny.", rating: 5 },
		{ text: "Highly recommended for food enthusiasts.", rating: 5 },
		{ text: "Exquisite dishes that pleased my taste buds.", rating: 5 },
		{ text: "A gem of a place, impeccable in every aspect.", rating: 5 },
		{ text: "Excellent selection of dishes on the menu.", rating: 5 },
		{ text: "A fantastic dining experience overall.", rating: 5 },
		{
			text: "The atmosphere was lovely, perfect for a date night.",
			rating: 4,
		},
		{ text: "Good food, but the service could be improved.", rating: 4 },
		{
			text: "Pleasantly surprised by the variety of flavors.",
			rating: 4,
		},
		{ text: "Well-prepared dishes and friendly staff.", rating: 4 },
		{ text: "Satisfying meals, suitable for a quick bite.", rating: 4 },
		{ text: "The food was okay, nothing special.", rating: 3 },
		{
			text: "Service could be better, but the taste was fine.",
			rating: 3,
		},
		{
			text: "An average experience, didn't leave a lasting impression.",
			rating: 3,
		},
		{ text: "Expected more value for the price.", rating: 2 },
		{ text: "Mediocre taste and lackluster presentation.", rating: 2 },
		{
			text: "Regret spending money on such a disappointing meal.",
			rating: 2,
		},
		{ text: "Not up to par with my expectations.", rating: 2 },
	],
};
